@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

html, body, #root {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: rgb(61, 70, 70);
}

.single-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.custom600{
 width: 700px;
 height: auto;
}

.custom700{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}
.classminheight{
  min-height: 100vh;
}


.custom1 {
   background-color: rgb(29, 12, 12);
   height:fit-content
}
.custom2 {
   background-color: rgb(29, 12, 12);
   
  
}
.customButton {
   border: solid 2px rgb(36, 46, 21);
  
}

.login {
   
   min-height: 100vh;
}

.custom4{
   min-height: 100vh;

}
.custom5{
   min-height: 120vh;

}

.custom9{
 width: 380px;
}

.toDelete{
   opacity: 0.5;
 }

 .up{
   position: fixed;   /* Fixed positioning */
     top:90%; 
     left: 10%;
     color:rgb(53, 234, 78);  /* Half of viewport width */
     transform: translate(-50%, -50%); 
     font-size: 90px;
     z-index:20000
 }

.confirmation-dialog{
   position: fixed;   /* Fixed positioning */
     top: 50%;          /* Half of viewport height */
     left: 50%;  
     color:rgb(243, 238, 238);
     background-color: black;
     padding: 15px;       /* Half of viewport width */
     transform: translate(-50%, -50%); 
     border-radius: 10px;
    border: solid white 2px;
     width:fit-content;
     z-index:20000
 
 }
 
 .confirmopen{
   opacity: 0.5;
 }

.custom6{
   padding-top: 90px;
}

.custom12{
  padding-top: 160px;
}

@media (max-width: 350px) { /* Adjust breakpoint as needed */
   .customButton {
    padding:1px 1px;
    font-size: 12px;
   }
 }
 @media (max-width: 210px) { /* Adjust breakpoint as needed */
   .customButton {
    padding:1px 1px;
    font-size: 10px;
   }
 }
 @media (max-width: 187px) { /* Adjust breakpoint as needed */
   .custom3 {

      display: flex;
      flex-wrap: wrap;
  
   }
 }
 @media screen and (max-width: 325px) {

 
   .up{
       font-size: 70px;
   }
  }
 @media screen and (max-width: 301px) {

 
   .custom6{
      padding-top: 90px;
   }
  }
 @media screen and (max-width: 230px) {

 
   .custom6{
      padding-top: 90px;
   }
  }
 @media screen and (max-width: 180px) {

 
   .custom6{
      padding-top: 100px;
   }
  }
 @media (max-width: 450px) {
   .custom9 {
   width:none; /* Even smaller size for mobile screens */
   }
  }
 @media (max-width: 170px) {
   .custom10 {
   padding-top: 250; /* Even smaller size for mobile screens */
   }
  }

  .titlefont{
  
    font-family: 'Pacifico', sans-serif;
   color: rgb(255, 255, 255);
   font-size: 4.5rem;
   padding-top: 0px;
   z-index: 10;
   text-shadow: 1px 1px 1px rgb(0, 0, 0),-1px -1px 1px rgb(0, 0, 0),1px -1px 1px rgb(0, 0, 0)
  
  }
  .titlefont2 {
    color: rgb(0, 0, 0); /* Original text color */
    font-size: 3.5rem;
    padding-top: 0px;
    z-index: 10;
    text-shadow: 1px 1px 1px rgb(255, 255, 255),-1px -1px 1px rgb(254, 254, 254),1px -1px 1px rgb(255, 255, 255)
  
  }

  

  .titlefont3 {
    color: rgb(255, 255, 255); /* Original text color */
    font-size: 3.5rem;
    padding-top: 16px;
    z-index: 10;
  
  }  
  .headerelements{
    position: relative;
    width: 100%;
      text-align: center;
  display: flex;
  z-index: 1;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  background-color: rgb(61, 70, 70);
  }  
  .imgh {
    position: absolute;
   
    top: 0%;
    z-index: 0; 
    padding: 0px;
    margin: 0px;
    width: 100%;
    height: 39vh;
  }  
  .imghn {
    position: absolute;
   
    top: 0%;
    z-index: 0; 
    padding: 0px;
    margin: 0px;
    width: 100%;
  
  }  
  .custom111{
    position: relative; 
    width: fit-content;
    margin-left: 5%;
    z-index: 2; 
  }
  .custom222{
    position: relative; 

    z-index: 2; 
  }
  nav {
    display: flex; 
    justify-content: center; 
    align-items: center; 
    height: 5vh;
  }
  .nav-list {
    list-style-type: none; 
    margin: 0;
    padding: 0;
    display: flex; 
    justify-content: center; 
    align-items: center; 
    flex-wrap: wrap; 
  }
  
  .nav-item {
    margin: 0 10px;
  }
  
  .nav-link {
    text-decoration: none;
    color: rgb(255, 255, 255); 
    font-size: 1.2rem;
    text-decoration: underline;
    transition: color 0.2s;
  }
  
  .nav-link:hover {
    border:solid #acc9e8;
    color: #b1cae5;
  }
  
  .navdivcontainer{
    background-color: rgb(0, 0, 0);
  }
  .up{
      position: fixed;
        top:90%; 
        left: 10%;
        z-index: 2000;
        color:rgb(39, 203, 56);
        transform: translate(-50%, -50%); 
        font-size: 90px;
    }
  
    .carticon{
      font-size: 25px;
    }
  
    .checkoutbutton{
      position: fixed;
      top: 40vh;
      right: 4vw;
   z-index: 6000;
      transform: translate(-50%, -50%); 
      border-radius: 20px;
      font-size: 20px;
    }
    .clearorder{
      position: fixed;
   top: 45vh;
      right: 5vw;
   z-index: 6000;
      transform: translate(-50%, -50%); 
      border-radius: 20px;
      font-size: 20px;
    }
  
    .cartcontainer{
      position:relative;
    }
  
    .cartbuttons{
      position: fixed;
      top: 7vh;
      right: 3vw;
   z-index: 6000;
    }
    .cartbtnselement{
  font-size: medium;
    }
  
    .aboutmediv{
      min-height: 100vh;
      padding-top: 180px;
      font-size: 1.5rem;
    }
    .aboutmediv2{
      min-height: 100vh;
      padding-top: 9%;
      font-size: 1.5rem;
    }
    .gallerydiv{
      min-height: 100vh;
      padding-top: 9vh;
      
    }



    .imgw{
      max-height: 80vh;
    }
    .navdivcontainer2{
      display: none;
    }
    .menu2{
      min-width: 100vw;
      width: 100%;
      padding: 0px;
      margin: 0px 0px;
    }
    .menu2 nav{

      margin: 22px 0px;
    }


    .limenu{
 
      padding: 8px 0px;
    }

    .menu{
      position: absolute;
      min-width: 100vw;
      top: 11%;
     
    }

    .menu ul{
      min-width: 100vw;
      background-color: black
    }

    .footertext{
      word-break: break-all;
    }

    .wcontainer{
      position: relative;
      min-height: 90vh;
      
    
    }
    .titlefontw1{
  
      font-family: 'Pacifico', sans-serif;
     color: rgb(255, 255, 255);
    opacity: 1;
    font-size: 2.8rem;
     padding-top: 0px;
     margin: 0px;
     z-index: 1000;
     position: relative;
     text-shadow: 1px 1px 1px rgb(0, 0, 0),-1px -1px 1px rgb(0, 0, 0),1px -1px 1px rgb(0, 0, 0)
    
    }
    .titlefontw2{
    
      font-family: 'Pacifico', sans-serif;
     color: rgb(255, 255, 255);
    
     opacity: 1;
     font-size: 1.6rem;
     padding-top: 0px;
     margin: 0px;
     z-index: 1000;
     text-shadow: 1px 1px 1px rgb(0, 0, 0),-1px -1px 1px rgb(0, 0, 0),1px -1px 1px rgb(0, 0, 0)
    
    }
  
    .btnfontw{
      font-weight: bolder;
      background-color: rgb(243, 238, 238);
    }
    
.welcomeimg{
  max-height: 110vh;
  max-width: 100vw;
}

.ppaldiv{
  width:500px;
  padding: 6px;
  margin-bottom: 5px;
  border-radius: 3px;
  z-index: 30000;
  
  margin: 0px auto;
  background-color: rgb(243, 238, 238);
}


.custom2000{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}


.carddivcontainer{
  width: 576px;
}





    @media screen and (max-width: 1020px) {
      .imgh {
        height: 32vh;
      }
      .aboutmediv{
        padding-top: 90px;
     
      }
      .gallerydiv{
        padding-top:4vh; 
      }
     }
    @media screen and (max-width: 915px) {
      .imgh {
        height: 28vh;
      }
      .titlefont{
       font-size: 3.5rem;
      }
      .titlefont2{ 
        font-size: 2.5rem;
      }
  
      .nav-link {
          font-size: 1.0rem;
      }
  
      .aboutmediv{
        
        padding-top: 110px;
     
      }
      .cartbuttons{
        
     
        right: 5vw;
   
      }
      .cartbtnselement{
    font-size: medium;
      }
     
    }
    @media screen and (max-width: 722px) {
      .imgh {
        height: 25vh;
      }
      .aboutmediv{
        
        padding-top: 70px;
     
      }
  
     }
    @media screen and (max-width: 647px) {
   
      .imgh {
        height: 21vh;
      }
      .titlefont{
        font-size: 2.8rem;
       }
       .titlefont2{ 
         font-size: 1.6rem;
       }
   
       .nav-link {
           font-size: 1.0rem;
       }
       nav {
         height: 3.5vh;
      }
  
      .aboutmediv{
        padding-top: 40px;
       
      }
      .gallerydiv{
        padding-top:2vh;
        
      }
      .cartbuttons{
     
        top: 17vh;
        right: 8vw;
     
      }

      .aboutmediv2{
          font-size: 1.2rem;
          padding: 0px;
          margin: 0px;
          padding-top: 7%;
         
      }

      .cartbtnselement{
    font-size: small;
      }






     }
     @media screen and (max-width: 580px) {
      .custom600{
         width:400px
       }

       .carddivcontainer{
        width: 100%;
        min-width: 100vw;
      }
      
     
     }
     @media screen and (max-width: 500px) {
      .custom600{
         width:350px
       }
     

     }

     @media screen and (max-width: 425px) {
      .custom600{
         width:300px
       }
     

     }
    @media screen and (max-width: 495px) {

   

      .imgh {
        height: 19vh;
      }
      .titlefont{
        font-size: 2.5rem;
       }
       .titlefont2{ 
         font-size: 1.8rem;
       }
  
      .up{
          font-size: 60px;
      }

      .cartbuttons{
     
        top: 19vh;
        right: 8vw;
     
      }

      .gallerydiv{
     
        padding-top: 6vh;
      min-height: 60vh;
        
      }

      .bw{
        margin: 13rem 0%;
      
      }
      .bw2{
        margin: 15rem 0%
      
      }
   
      .ppaldiv{
        width:100%;
     
        
     }
    }

    
    @media screen and (max-width: 365px) {
      .custom600{
         width:250px
       }

       .navdivcontainer{
        display: none;
           }
           .navdivcontainer2{
            position:sticky;
            z-index: 20000;
            top: 10%;
            display: block;
            padding: 0;
            margin: 0;
            min-height: 3.9vh;
            color: rgb(255, 255, 255);
            background-color:rgb(27, 30, 30)
          }

          .myburger{
            position: absolute;
            top: 10%;
            right: 8%;
            font-size: 1.4rem;
            cursor:pointer
            
          }
     

     }
    @media screen and (max-width: 352px) {

      .imgh {
        height: 22vh;
      }
  

      .aboutmediv2{
        font-size: 1.0rem;
           }


          
      .cartbuttons{
     
        top: 19vh;
        right: 8vw;
     
      }

      .aboutmediv2{
        padding-top: 14%;
       
    }

    .wcontainer{
      position: relative;
      min-height: unset;
      height: 80vh;
      
    
    }

    .welcomeimg{
      max-height: unset;
      max-width: unset;
      width: 100vw;
    }
  
     }
     @media screen and (max-width: 310px) {
      .custom600{
         width:240px
       }
     

     }
     @media screen and (max-width: 292px) {
      .custom600{
         width:150px
       }

       .btnfontw{
        margin-top: 5px;
       }
     

     }
    @media screen and (max-width: 275px) {
      .imgh {
        height: 18vh;
      }
      .titlefont{
        font-size: 2.0rem;
       }
       .titlefont2{ 
         font-size: 1.5rem;
       }


  
     }
    @media screen and (max-width: 209px) {
      .imgh {
        height: 13vh;
      }
      .titlefont{
        font-size: 2.0rem;
       }
       .titlefont2{ 
         font-size: 1.4rem;
       }
  

                 
      .cartbuttons{
     
        top: 28vh;
        right: 8vw;
     
      }
     }
  
     @media screen and (max-width: 180px) {
      .imgh {
        height: 10vh;
      }
   
       .nav-link {
           font-size: 0.8rem;
       }
       nav {
         height: 6.5vh;
      }
  
     }

     .no-border {
      border-radius:unset;
      background: transparent;
      border: none !important; /* Important to override Bootstrap's default styling */
      box-shadow: none !important; /* Optional: removes the shadow for a completely flat look */
    }
    


    th{
      margin: 0px;
      padding: 0px;
    }
    tr{
      margin: 0px;
      padding: 0px;
    }